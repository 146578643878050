
.slogo{
    width: 100px;
}

.link{
    display: flex;
    align-items: center;
    color: #fff;
    padding: 10px 15px;
    gap: 30px;
    transition: all 0.5s;
}
.link:hover{
    color: #EF5B2C;
    transition: all 0.5s;
}
.active{
    background: #EF5B2C;
    color: #fff;
}
.icon, .link_text{
    font-size: 15px;
}
.bottom-section{
    width: 100%;
    height: 5vh;
    margin-top: 75%;
}
.Adminavatar{
    display: flex;
   justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    background-color: #fff;
    border-radius: 50%;
    object-fit: contain;
    margin-bottom: 20px;
}